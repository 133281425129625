import React from "react"
import { Link } from "gatsby"
import { Button } from "react-bootstrap"
import item1 from "../../assets/images/home/complete.png"
import item2 from "../../assets/images/home/1vs1.png"
// import ScrollReveal from 'scrollreveal'

const Brand = () => {
  return (
    <div className="brand-bg">
      <div className="brand-box">
        <div className="brand">
          <div className="title foo1">Why Choose BBA</div>
          <div className="text foo1">
            Thinking about starting a new business or bringing your business
            online? <br />
            We can help!
            <br />
            BBA has helped 10,000+ small business owners. <br />
            Come find out how we did this.
          </div>
          {/* <Carousel interval={null}>
            <Carousel.Item> */}
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="item foo1">
              <img src={item1} className="item-img" />

              <div className="item-title">All-in-One solution</div>
              <div className="item-text">
                You can always searching you need for your own business. BBA has
                advantages on product hunting, website building, online
                marketing, payment method, inventory management and etc. Most of
                our tools only need a few minutes to learn & set up. So you can
                focus on your customers & business.
              </div>
              {/* <Button className="more" ><Link to="/contact">Learn More</Link></Button> */}
            </div>
            <div className="item foo1">
              <img src={item2} className="item-img" />
              <div className="item-title">Professional Support</div>
              <div className="item-text">
                Get the help you need, every step of the way. Contact support
                24/7, whether you're troubleshooting issues or looking for
                business advice.We can even help you to hire an expert to help
                you with everything from store setup to SEO.
              </div>
              {/* <Button className="more" ><Link to="/contact">Learn More</Link></Button> */}
            </div>
          </div>
          {/* </Carousel.Item>
            <Carousel.Item>
              <div style={{display:'flex',justifyContent:'space-between'}}>
                <div className="item foo1">
                  <svg className="icon">
                    <use href='#iconConnect'></use>
                  </svg>
  
                  <div className="item-title">Connect</div>
                  <div className="item-text">
                    Docking the mainstream operating system and cooperating with the top cross-border EPR of China.
                  </div>
                  <Link className="more" to="/contact">——— Learn more</Link>
                </div>
                <div className="item foo1">
                  <svg className="icon">
                    <use href='#iconImprove'></use>
                  </svg>
                  <div className="item-title">Improve</div>
                  <div className="item-text">
                    Integrate multiple money collection channels, place orders quickly with one click, enhance shopping experience and increase conversion rate.
                  </div>
                  <Link className="more" to="/contact">——— Learn more</Link>
                </div>
              </div>
            </Carousel.Item>
          </Carousel> */}
        </div>
        <div className="web-temp foo1"></div>
      </div>
    </div>
  )
}

export default Brand
