import React from "react"
import { Link } from "gatsby"
import { Container, Button } from "react-bootstrap"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Jumpbotron = () => {
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "home/0011.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(relativePath: { eq: "home/0000.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]
  return (
    <div style={{ background: "#E6EFFE" }} className="foo1">
      <Container className="jumpbotron">
        <div className="jumpbotron-left">
          <div className="foo1">BigBigAds</div>
          <div className="foo1">Sell anytime, anywhere to anyone. </div>
          <div className="foo1">
            Bring your idea to life with the tools you need to start your own
            online business. <br />
            BBA is trusted by over 10,000+ small business owners worldwide.{" "}
            <br />
            Start your business now by taking a few easy steps.
          </div>
          {/* <Link to='/contact'><Button className='foo1'>Learn More</Button></Link> */}
        </div>
        {/* <div className='jumpbotron-img'></div> */}
        <div className="jumpbotron-right">
          <div className="foo1 right-top "></div>
          <div className="foo1 right-bottom "></div>
          <Img fluid={sources} className=" foo1 jumpbotron-img" />
        </div>
      </Container>
    </div>
  )
}

export default Jumpbotron
